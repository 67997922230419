.post {
    display: flex;
    flex-direction: column;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.post-contentTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom:50px;
}

.post-contentSumari {
    width: 90%;
    margin-bottom:50px;
}

.post-photoD {
    width: 70%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}

.post-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.post-content {
    width: 90%;
}

.post-content-box {
    margin-top:10px;
    margin-bottom: 40px;
}

@media (max-width: 500px) {
    .post-photoD {
        width: 100%;
    }
  }