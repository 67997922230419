/* src/App.css */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  background-color: #333;
  color: #fff;
  padding: 1rem;
  text-align: center;
}

.nav {
  background-color: #333;
  color: #fff;
  padding: 0.5rem 1rem;
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
}

nav ul li {
  display: inline;
  margin-right: 1rem;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
}

main {
  flex: 1;
  padding: 2rem;
}

.post {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.post-content {
  margin-bottom: 1.5rem;
}

.post-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999;
  font-size: 0.9rem;
}

.author {
  margin-right: 1rem;
}

.ads {
  text-align: center;
  padding: 2rem 0;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 1rem;
}

.post-detail {
  padding: 2rem;
}

.ad {
  text-align: center;
  margin: 2rem 0;
  background-color: #f5f5f5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
}

.ad-top {
  margin-top: 0;
}

.ad-bottom {
  margin-bottom: 0;
}

.ad-card {
  margin-top: 0.5rem;
}

.ad-right {
  position: sticky;
  top: 2rem;
  align-self: flex-start;
}

.ad-post-right {
  text-align: center;
  margin-top: 2rem;
  background-color: #f5f5f5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
  position: sticky;
  top: 2rem;
  align-self: flex-start;
}

.ad-post-bottom {
  text-align: center;
  margin: 2rem 0;
  background-color: #f5f5f5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
}

.not-found {
  text-align: center;
  padding: 2rem;
}

.not-found ul {
  list-style: none;
  padding: 0;
}

.not-found ul li {
  margin-bottom: 1rem;
}

.not-found ul li a {
  color: #333;
  text-decoration: none;
}

.post-preview {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.post-preview h3 {
  margin-bottom: 2.5rem;
}

.post-photo-container {
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 50px;
}

.post-photoH {
  width: 30%;
}

.post-excerpt {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #666;
}

.post-preview a {
  color: #007bff;
  text-decoration: none;
}

.recommended-posts {
  padding: 2rem;
}

.recommended-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
}

.recommended-post {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
}

.recommended-post h4 {
  margin-bottom: 0.5rem;
}

.recommended-post a {
  color: #007bff;
  text-decoration: none;
}

/* Responsive styles */
@media (max-width: 768px) {
  main {
    padding: 1rem;
  }
  .ads {
    padding: 1rem 0;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.post-list {
  flex: 2;
}

.sidebar {
  flex: 1;
  padding-left: 2rem;
  border-left: 1px solid #ddd;
}

/* Responsive styles */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }
  .sidebar {
    flex: none;
    border-left: none;
    padding-left: 0;
    margin-top: 1rem;
  }
}

.about {
  padding: 4rem;
  text-align: center;
}

.about h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.about-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin-top: 3rem;
}

.about-photo {
  max-width: 300px;
  border-radius: 50%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.about-photo:hover {
  transform: scale(1.05);
}

.about-text {
  flex: 1;
}

.about h3 {
  font-size: 1.8rem;
  margin-top: 2rem;
}

.about p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
}

/* Responsive styles */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
    align-items: center;
  }

  .about-photo {
    max-width: 200px;
    margin-bottom: 1rem;
  }
}