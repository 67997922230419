
.contactUs-container {
    display: flex;
    justify-content: space-evenly;
    padding: 50px 0px;
}

.contactUs-form-container {
    width: 40%;
}

.form-box {
    display: flex;
    flex-direction: column;

}

.name, .email, .message {
    display: flex;
    justify-content: space-between;
    margin: 5px 0px;
}

.submit-button {
    border: 1px solid white;
    color: rgb(255, 255, 255);
    background-color: #333;
    border-radius: 10px;
    height: 30px;
}

.contactUs-inf{
    height: 100%x;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.contactUs-inf-content{
    display: flex;
    align-items: center;
    justify-content: start;
}

.icon {
    width: 30px;
    margin-right: 10px;
}

@media (max-width: 500px) {
    .contactUs-container {
        flex-direction: column;
        padding: 10px;
        align-items: center;
        margin-bottom: 20px
    }
    .contactUs-form-container {
        width: 90%;
        margin-bottom: 20px
    }
    .contactUs-inf-content{
        width: 90%;
    }
  }